<!-- eslint-disable vue/valid-v-for -->
<template>
  <div class="chat-container">
    <div>
      <div class="chat-messages" ref="chatMessages">
        <p class="file-count">Attached Files: {{ filesCount }}</p>
        <div class="chat-messages__header">
          <button v-if="!isLoadingPreviousChat" class="chat-messages__load-more ed-btn__blim" @click="loadPreviousMessages" :disabled="isLoadingPreviousChat">
            <i class='bx bx-chevron-up box-icon box-icon--disabled'></i>
            <span >Load Previous Messages</span>
          </button>
          <span v-else class="spinner-border text-secondary"></span>
        </div>
        <a-list item-layout="horizontal" :data-source="messages">
          <template #renderItem="{ content, role, id }">
            <div v-for="singleMessage in content" :key="singleMessage">
              <li
                v-for="(singleMessage, index) in content"
                :key="index"
              >
                <div v-if="singleMessage.type === 'text'" class="text-message">
                  <div class="message-header">
                  <img
                    class="tj-icon"
                    v-if="role === 'assistant'"
                    src="../../../assets/images/tj-logo.png"
                  />
                  <i class='bx bx-user-circle user-profile' v-if="role === 'user'"></i>
                <div :class="getMessageClass(role)">
                      <!-- Render content dynamically -->
                      <div v-html="formattedContent(singleMessage.text)"></div>
                      <div v-if="showSourcesList && sourceMessageID == id && role === 'assistant'">
                        <ul>
                      <li v-for="(link, index) in limitedLinks" :key="index">
                        <a :href="link" target="_blank">{{ link }}</a>
                      </li>
                      </ul>
                      </div>
                      <button class="ed-btn ed-btn__blim execute-btn" v-if="hasCodeBlock(singleMessage.text.value)" @click="executeCode(singleMessage.text.value)">
                        Execute
                      </button>
                      <div class="suggestions-container" v-if="showSuggestions && playstreamMessageId == id && role === 'user'">
                        <button v-for="(suggestion, index) in suggestions" :key="index" class="suggestion-button" @click="applySuggestion(suggestion)">
                          {{ suggestion }}
                        </button>
                      </div>
                      <div class="icon-buttons" v-if="role === 'assistant'">
                      <i :class="['bx', playStream && this.playstreamMessageId === id ? 'bx-volume-full' : 'bx-volume-mute', 'play-stream']" @click="playStreamClicked(singleMessage.text.value, id)" v-if="role === 'assistant'"></i>
                      <i class="bx bx-copy" @click="copyMessage(singleMessage.text.value)"></i>
                      <i class='bx bx-link-external' @click="toggleSources(id)"></i>
                    </div>
                    <div class="icon-buttons" v-if="role === 'user'">
                      <i class='bx bx-refresh' @click="refreshResponse(id)"></i>
                      <i class='bx bx-help-circle help' @click="toggleSuggestions(id)"></i>
                    </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="singleMessage.type === 'image_url'" class="image-message">
                  <img :src="singleMessage.image_url.url" style="width: 100%" />
                </div>
                <div v-else>Not a valid content</div>
              </li>
            </div>
          </template>
        </a-list>
      </div>
      <div class="input-container">
        <div class="recorder">
        <VoiceAssistant :isTyping="isTyping" :playStream="playStream" @tts-ended="handleTTSEnded" />
      </div>
      <div class="box-container">
        <div v-if="uploadingFile" class="loader-overlay">
          <div class="loader"></div>
        </div>
        <div class="preview-box">
          <div
            v-for="(item, index) in imagePreviews"
            :key="index"
            class="preview-item"
          >
            <div v-if="item.type === 'image'" class="image-preview">
              <img :src="item.url" />
              <i class="bx bx-x-circle" @click="removeImage(index)"></i>
            </div>
            <div v-else class="file-preview">
              <i id="attachment" class="bx bx-paperclip bx-rotate-270"></i>
              <span>{{ item.fileName }}</span>
              <i class="bx bx-x-circle" @click="removeImage(index)"></i>
            </div>
          </div>
        </div>
        <div class="chat-input">
          <div class="upload-menu">
            <i class="bx bx-paperclip bx-rotate-270 " data-bs-toggle="dropdown" aria-expanded="false"></i>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">
              <label for="file-upload">
                Upload File
              </label>
             <input id="file-upload" type="file" @change="handleFileUpload" multiple />
            </a></li>
            <li><a class="dropdown-item" href="#">
              <label for="folder-upload">
                Upload Folder
              </label>
             <input id="folder-upload" type="file" @change="handleFileUpload" multiple webkitdirectory/>
            </a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">
              <label for="file-upload">
                Upload Image
              </label>
             <input id="file-upload" type="file" @change="handleFileUpload" multiple />
            </a></li>
          </ul>
        </div>
          <textarea
            v-model="newMessage"
            @keydown="handleKeyDown"
            @input="adjustHeight"
            placeholder="Message Assistant"
            ref="textarea"
            class="chat-textarea"
          />
          <button class="send-button" @click="sendMessage">
            <i class="bx bx-up-arrow-alt"></i>
          </button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import axios from 'axios';
import Papa from 'papaparse';
import VoiceAssistant from '../../components/VoiceAssistant.vue';

export default {
  data() {
    return {
      newMessage: this.$store.state.transcription,
      graphId: '',
      imageFile: null,
      messages: [],
      imagePreviews: [],
      uploadingFile: false,
      isTyping: false,
      pendingTTS: '',
      isLoadingPreviousChat: false,
      isFocused: false,
      isStreaming: false,
      copiedMessageIndex: null,
      lastQuery: '',
      playStream: false,
      latestAssistantMessage: '',
      streamMessage: '',
      suggestions: [],
      showSuggestions: false,
      sources: '',
      showSourcesList: false,
      filesCount: null,
      playstreamMessageId: null,
      sourceMessageID: null,
    };
  },
  components: {
    VoiceAssistant,
  },
  props: {
    assistantId: String,
    threadId: String,
    vectorStoreId: String,
    initialQuestion: String,
  },
  async mounted() {
    this.fetchThreadMessages();
    this.adjustHeight();
    window.addEventListener('paste', this.handlePaste);
    this.$refs.textarea.addEventListener('focus', this.handleFocus);
    this.$refs.textarea.addEventListener('blur', this.handleBlur);
    if (this.initialQuestion) {
      this.newMessage = this.initialQuestion;
      this.sendMessage();
    }
  },
  computed: {
    transcriped() {
      return this.$store.state.transcription;
    },
    limitedLinks() {
      return this.sources.slice(0, 3); // Return only the first 3 links
    },
  },
  watch: {
    threadId() {
      console.log('thread changed');
      console.log('Vector Store ID:', this.vectorStoreId);
      this.fetchVectorStoreFiles(this.vectorStoreId);
      // Vector Store ID: vs_CwxlHEiSK6MQOLpSUeP8uUWB
      this.fetchThreadMessages();
    },
    transcriped(newVal) {
      this.newMessage = newVal;
      this.sendMessage();
    },
    playstreamMessageId(newValue) {
      console.log('playstreamMessageId updated:', newValue);
    },
  },

  methods: {
    async fetchVectorStoreFiles(vectorStoreId) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/vector-store/files-count`, {
          id: vectorStoreId,
        });
        console.log('Files:', response.data);
        this.filesCount = response.data.fileCount;
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    async fetchSuggestions(text) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/prompt-suggestions`, {
          prompt: text,
        });

        this.suggestions = response.data.contents.suggestions || [];
        console.log(this.suggestions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    applySuggestion(suggestion) {
      this.newMessage = suggestion;
      this.sendMessage();
      this.showSuggestions = false;
    },
    toggleSuggestions(id) {
      this.showSuggestions = !this.showSuggestions;
      if (this.showSuggestions) {
        this.playstreamMessageId = id;
        const userMessage = this.messages.find((message) => message.id === id);
        this.fetchSuggestions(userMessage.content[0].text.value);
        console.log(id);
      } else {
        this.playstreamMessageId = null;
      }
    },
    toggleSources(id) {
      this.showSourcesList = !this.showSourcesList;
      if (this.showSourcesList) {
        this.sourceMessageID = id;
        console.log(id);
      } else {
        this.sourceMessageID = null;
      }
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    },
    formattedContent(content) {
      const { value, annotations } = content;
      let sanitizedContent = value;
      console.log(content);
      if (annotations) {
        if (annotations.length) {
          annotations.forEach((ann) => {
          // eslint-disable-next-line camelcase
            const { file_citation, text } = ann;
            sanitizedContent = sanitizedContent.replaceAll(
              text,
              `<a href="/file/${file_citation.file_id}" target="_blank">${text}</a>`,
            );
          });
        }
      }

      return marked(sanitizedContent);
    },
    generateRandomNumber() {
      const min = 1; // Set the minimum value of the range
      const max = 100; // Set the maximum value of the range
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetchThreadMessages() {
      const threadMessages = await this.$store.dispatch(
        'eddie/fetchMessagesUsingThreadId',
        { threadId: this.threadId },
      );

      this.messages = threadMessages.reverse();
      console.log('this main', this.messages);
    },
    async loadPreviousMessages() {
      this.isLoadingPreviousChat = true;
      const threadMessages = await this.$store.dispatch(
        'eddie/fetchMessagesUsingThreadId',
        {
          threadId: this.threadId,
          after: this.messages[0].id,
        },
      );
      this.messages = [...threadMessages.reverse(), ...this.messages];
      this.isLoadingPreviousChat = false;
      // this.messages = threadMessages.reverse();
      console.log('this main', this.messages);
    },
    hasCodeBlock(content) {
      const codeBlockRegex = /```[\s\S]*?```/g;
      return codeBlockRegex.test(content);
    },
    extractCodeBlock(content) {
      const codeBlockRegex = /```(?:\w+)?\s*([\s\S]*?)```/;
      const match = content.match(codeBlockRegex);
      return match ? match[1].trim() : '';
    },
    async executeCode(content) {
      const code = this.extractCodeBlock(content);
      console.log('Executing code:', code);

      try {
        const response = await axios.post(`${process.env.VUE_APP_ANALYTICS_URL}/run-script/`, { code }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('xvf', 'Execution result:', response.data);
        const newContentArr = {
          assistantId: null,
          attachments: null,
          content: [
            {
              image_url: {
                annotations: [],
                url: `${process.env.VUE_APP_ANALYTICS_URL}/static/generated_images/${response.data.output.trim()}`,
              },
              type: 'image_url',
            },
          ],
        };
        this.messages.push(newContentArr);
        // You can handle the response and display the result in the UI as needed
        console.log('xvf', 'this messages', this.messages);
      } catch (error) {
        console.error('xvf', 'Error executing code:', error);
        // Handle the error as needed, possibly displaying a message in the UI
      }
    },
    async makeEddieSearchUsingAssistantAndThread() {
      try {
        this.isStreaming = true;
        const tempQuery = this.newMessage;
        this.newMessage = '';
        this.messages.push({
          content: [
            {
              type: 'text',
              text: {
                value: '',
                annotations: [],
              },
            },
          ],
          role: 'assistant',
        });
        const imageInput = this.imageFile && this.imageFile.type.match(/^image\/(png|jpeg|gif)$/)
          ? this.imageFile
          : null;
        await this.$store.dispatch(
          'eddie/streamResponseWithAssistantAndThreadId',
          {
            userPrompt: tempQuery,
            imageInput,
            threadId: this.threadId,
            assistantId: this.assistantId,
            callback: async (chunk) => {
              console.log(this.messages[this.messages.length - 1]);
              console.log('xvf', 'harp', chunk);
              if (chunk.includes('msg_ended')) {
                console.log('xvf', 'Here is break point', JSON.parse(chunk.split('msg_ended')[1]));
                this.message[this.message.length - 1] = JSON.parse(chunk.split('msg_ended')[1]);
              } else {
                this.messages[this.messages.length - 1].content[0].text.value += chunk;
              }
            },
          },
        );
        // this.sendToAssistant(assistantResponse);
        this.isStreaming = false;
        const assistantResponse = this.messages[this.messages.length - 1].content[0].text.value;
        try {
          const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/google-search`, {
            query: assistantResponse,
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log('Source:', response.data);
          this.sources = response.data.map((result) => result.link);
        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
        }
      } catch (error) {
        console.error('Error:', error);
        this.messages.push({
          content: [
            {
              type: 'text',
              text: {
                value: 'Something went wrong while processing your request. Please try again.',
              },
            },
          ],
          role: 'assistant',
        });
      }
    },
    async handleFileUpload(event) {
      const { files } = event.target;

      // Ensure the file is not undefined
      if (!files.length) {
        console.error('No file uploaded');
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log(file, 'uploaded');

        if (file.type.match(/^image\/(png|jpeg|gif)$/)) {
          this.imageFile = file;
          const imageUrl = URL.createObjectURL(file);
          this.imagePreviews.push({ type: 'image', url: imageUrl });
          console.log('This file is an image. Proceed with the operations.');
        } else if (file.type.match(/^text\/csv$/)) {
          try {
            Papa.parse(file, {
              header: true,
              complete: (results) => {
                const dataInJSON = results.data;

                // Create a JSON file from the JSON data
                const jsonBlob = new Blob([JSON.stringify(dataInJSON)], { type: 'application/json' });
                const jsonFile = new File([jsonBlob], file.name.replace('.csv', '.json'), { type: 'application/json' });
                this.uploadingFile = true;
                this.imageFile = jsonFile;
                this.imagePreviews.push({ type: 'file', fileName: file.name });
                this.updateMemoryOfThread(jsonFile).then(() => {
                  this.imageFile = null;
                });
              },
              error: (error) => {
                console.error('Error processing CSV file:', error);
              },
            });
          } catch (error) {
            console.error('Error processing CSV file:', error);
          }
        } else {
          this.uploadingFile = true;
          this.imageFile = file;
          this.imagePreviews.push({ type: 'file', fileName: file.name });
          this.updateMemoryOfThread(file);
          this.imageFile = null;
        }
      }
    // only if its not jpg/png etc
    },
    convertCsvToJson(csv) {
      const lines = csv.split('\n');
      const headers = lines[0].split(',');
      return lines.slice(1).map((line) => {
        const values = line.split(',');
        return headers.reduce((acc, header, index) => {
          acc[header] = values[index];
          return acc;
        }, {});
      });
    },
    async updateMemoryOfThread(file) {
      console.log('vector is being updated. Proceed with the operations.');
      console.log('storeid', this.vectorStoreId);
      if (!this.vectorStoreId) return;

      const formData = new FormData();
      formData.append('vectorStoreId', this.vectorStoreId);
      formData.append('doc', file);

      try {
        const response = await fetch(
          `${process.env.VUE_APP_AXIOS_URI}/eddie/smart-vector-update`,
          {
            method: 'POST',
            headers: {
              // No Content-Type header needed for FormData
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
            body: formData,
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            `HTTP error! Status: ${response.status}, Message: ${errorData.message}`,
          );
        }

        const data = await response.json();
        console.log('Success:', data);
        this.fetchVectorStoreFiles(this.vectorStoreId);
        const eventPayload = {
          verb: 'FILE_UPLOADED_TO_VECTOR_STORE',
        };
        this.$store.dispatch('actionFeedback', eventPayload);
        console.log('File is uploaded!');
        this.uploadingFile = false;
        this.imageFile = null;
        this.imagePreviews = [];
      } catch (err) {
        console.error('Error:', err.message);
      }
    },
    async sendMessage() {
      if (this.newMessage.trim() === '') return;
      const content = [];
      this.lastQuery = this.newMessage;
      console.log(this.imageFile);

      content.push({
        type: 'text',
        text: {
          value: this.newMessage,
        },
      });

      if (
        this.imageFile
        && this.imageFile.type.match(/^image\/(png|jpeg|gif)$/)
      ) {
        content.push({
          type: 'image_url',
          image_url: {
            url: URL.createObjectURL(this.imageFile),
            detail: 'auto',
          },
        });
      }

      this.messages.push({
        role: 'user',
        content,
      });

      //   this.newMessage = '';
      this.imagePreviews = [];
      await this.makeEddieSearchUsingAssistantAndThread();
      await this.$store.dispatch(
        'eddie/fetchMessagesUsingThreadId',
      );
      this.imageFile = null;
      this.scrollToBottom();
    },
    removeImage(index) {
      this.imagePreviews.splice(index, 1);
    },
    getMessageClass(type) {
      return {
        'user-message': type === 'user',
        'assistant-message': type === 'assistant',
      };
    },
    handleKeyDown(event) {
      if (event.key === 'Enter' && event.shiftKey) {
        return;
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        this.sendMessage();
      }
    },
    scrollToBottom() {
      const { chatMessages } = this.$refs;
      if (chatMessages) {
        chatMessages.scrollTop = chatMessages.scrollHeight;
      }
    },
    adjustHeight() {
      this.isTyping = this.newMessage.length > 0;
      console.log(this.isTyping);
      const { textarea } = this.$refs;
      textarea.style.height = '25px';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    handlePaste(event) {
      if (!this.isFocused) {
        return; // Exit if the chat box is not focused
      }
      event.preventDefault();
      const clipboardData = (event.clipboardData || event.originalEvent.clipboardData).getData('text/html');
      if (!this.newMessage) {
        this.newMessage = '';
      } else {
        const plainText = (event.clipboardData || event.originalEvent.clipboardData).getData('text/plain');
        this.newMessage += plainText;
      }
      if (clipboardData) {
        this.parseHtmlAndExtractImages(clipboardData);
      }
    },
    parseHtmlAndExtractImages(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const imageElements = Array.from(doc.images);
      imageElements.forEach((img) => {
        fetch(img.src)
          .then((response) => response.blob())
          .then((blob) => {
            const imageUrl = URL.createObjectURL(blob);
            const file = new File([blob], 'image.png', { type: blob.type });
            this.imagePreviews.push({ type: 'image', url: imageUrl });
            this.imageFile = file;
          });
      });
      if (!this.newMessage) {
        this.newMessage = '';
      }
      const text = doc.body.textContent || '';
      this.newMessage += text.trim();
    },
    copyMessage(text) {
      const eventPayload = {
        verb: 'COPY_TO_CLIPBOARD',
      };
      this.$store.dispatch('actionFeedback', eventPayload);
      navigator.clipboard.writeText(text).then(() => {
        console.log('Message copied to clipboard!');
      // You can add a notification or visual feedback here if needed
      }).catch((err) => {
        console.error('Failed to copy message: ', err);
      });
    },
    async refreshResponse(id) {
      console.log(id);
      const messageID = id;
      const userMessage = this.messages.find((message) => message.id === messageID);
      console.log(userMessage.content[0].text.value);
      this.newMessage = userMessage.content[0].text.value;
      await this.makeEddieSearchUsingAssistantAndThread();
    },
    isLastMessage(singleMessage) {
      const lastMessage = this.messages[this.messages.length - 1];
      return lastMessage && lastMessage.content && lastMessage.content.includes(singleMessage);
    },
    playStreamClicked(text, id) {
      this.playStream = !this.playStream;
      this.playstreamMessageId = id;
      this.streamMessage = text;
      if (this.playStream) {
        this.$store.dispatch('audioText', this.streamMessage);
        this.streamMessage = '';
      }
      console.log(this.playStream);
    },
    handleTTSEnded() {
      this.playStream = false; // Mute the icon when TTS ends
      this.playstreamMessageId = null;
    },
  },
  beforeDestroy() {
    window.removeEventListener('paste', this.handlePaste);
  },
};
</script>

<style lang="scss" scoped>
.icon {
  transform: scale(2);
  margin: 10px;
}
.tj-icon {
  width: 35px;
  height: 35px;
  margin-top: 20px;
}
input[type="file"] {
  display: none;
}
.icon:hover {
  cursor: pointer;
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.animated-dot {
  height: 10px;
  width: 10px;
  background-color: #010148;
  border-radius: 50%;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__load-more {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.loader-overlay {
  margin-left: 10px;
  margin-top: 10px;
}
.preview-item {
  padding: 10px;
  position: relative;
}
.image-preview .bx {
  position: absolute;
  top: 3px;
}
.user-message img {
  width: 90px;
  padding: 5px;
  border-radius: 10px;
  background-color: none;
}

.user-message {
  background-color: #f7f7f7;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  margin: 10px;
  margin-bottom: 20px;
  float: left;
  text-align: left;
  align-items: center;
}
.user-message p {
  margin: 0px;
}
.assistant-message {
  background-color: #fefdf5;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  margin: 10px;
  margin-bottom: 20px;
  float: right;
  text-align: left;
  align-items: center;
}
.assistant-message p {
  margin: 0px;
}
.chat-textarea {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;
  width: 100%;
  height: 25px;
  min-height: 25px;
  margin-left: 5px;
}
.chat-textarea:focus {
  outline: none;
  box-shadow: none;
}
#attachment {
  color: black;
  font-size: 13px;
  margin-left: 5px;
}
.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
}
.input-container{
  display: flex;
  align-items: center;
}
.preview-box img {
  width: 40px;
  border-radius: 5px;
  margin-left: 25px;
}
.preview-box .bx {
  font-size: 13px;
  cursor: pointer;
  color: red;
}
.chat-input label {
  font-size: 10px;
  margin-top: 3px;
  border-radius: 30px;
}
.message-header {
  display: flex;
  align-items: flex-start;
}
.chat-input label .bx {
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  color: #a5a5a5;
}
.icon-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  cursor: pointer;
  margin-left: auto;
  justify-content: flex-end;
}
.box-container {
  display: block;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  margin-bottom: 10px;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #a5a5a5;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.send-button {
  margin-top: auto;
  padding: 4px;
  margin-bottom: auto;
  width: 25px;
  height: 25px;
  border: none;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  color: #0964b0;
  border-radius: 50%;
  cursor: pointer;
}
.send-button:hover {
  background-color: #f0f0f0;
}
.send-button .bx {
  font-size: 16px;
  font-weight: 500;
  color: #a5a5a5;
}
.upload-menu {
  cursor: pointer;
  color: #a5a5a5;
  font-size: 15px;
}
.preview-box {
  display: flex;
  flex-wrap: wrap;
}
.execute-btn {
  margin-top: 10px;
}
.recorder {
  margin-right: 15px;
  margin-left: 5px;
  margin-top: -10px;
}
.play-stream {
  cursor: pointer;
}
.suggestion-button {
  border-radius: 20px;
  padding: 20px;
  background-color: #eeeef5;
  border: none;
  margin: 0.5rem;
}
.suggestion-container {
  display: flex;
  flex-direction: column;
}
.user-profile {
  font-size: 35px;
  color: #d0d0d0;
  margin-top: 20px;
  margin-right: 10px;
}
.file-count {
  text-align: right;
}
</style>
